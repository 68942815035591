footer {
    background-color: #FFFFFF;
    border-top: 1px solid #DDDDDD;
    position: sticky;
    bottom: 0;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    margin: 0 70px;
}

.footer-left {
    font-size: 14px;
    line-height: 18px;
    color: #222222;
}

.footer-right {
    font-size: 14px;
    line-height: 18px;
    color: #222222;
}

.footer-right a {
    text-decoration: none;
    color: #222222;
}

.footer-right a:hover {
    font-weight: 600;
}