.review-form-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    border-bottom: 1px solid rgb(235, 235, 235);
    font-size: 18px;
    line-height: 20px;
    font-weight: 800;
}

.review-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 12px 0;
}

.review-description h1 {
    font-size: 16px;
    font-weight: 600;
}

.review-description h2 {
    font-size: 14px;
    color: rgb(113, 113, 113);
}

.review-form-content {
    margin: 0 48px 24px 48px;
}

.attribute-ratings-container {
    display: grid;
    grid-template-columns: auto auto;
}

.attribute-rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px 0;
}

.attribute-rating h1 {
    font-size: 16px;
    font-weight: 600;
}

.review-comment {
    margin-top: 6px;
    width: 100%;
    resize: none;
}

.review-form-errors {
    display: flex;
    flex-direction: column;
    color: rgb(193, 53, 21);
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin: 10px 0 10px;
}

.review-submit {
    cursor: pointer;
    text-align: center;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    border-radius: 8px;
    padding: 14px 24px;
    border: none;
    background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
    color: rgb(255, 255, 255);
    width: 100%;
}