/* TODO Add site wide styles */

#root {
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    font-size: 14px;
    line-height: 1.43;
    color: #222;
    background-color: #fff;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    display:grid; 
    grid-template-rows: auto 1fr auto;
    min-height:100vh; 
}