.menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 21px;
    padding: 5px 5px 5px 12px;
    color: #717171;
    margin-left: 165px;
}

#hamburger-icon {
    font-size: 16px;
    width: 16px;
}

#profile-icon {
    width: 30px;
    margin-left: 12px;
    font-size: 30px;
}

.profile-dropdown {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 12px;
    height: 228px;
    margin-top: 13px;
    padding: 8px 0;
    position: absolute;
    width: 240px;
}

.drop-down-menu-options-container {
    width: 100%;
    line-height: 18px;
    font-size: 14px;
}

.drop-down-menu-options-container:hover {
    cursor: default;
}

.drop-down-menu-options-container-link:hover {
    cursor: pointer;
    background-color: #F7F7F7;
}

.drop-down-reservations-link {
    text-decoration: none;
    color: #222222;
}

.drop-down-menu-options-bold {
    font-weight: 600;
    padding: 12px 16px;
}

.drop-down-menu-options {
    padding: 12px 16px;
}

.drop-down-menu-divider {
    width: 100%;
    margin: 8px 0;
    border: 1px solid #DDDDDD;
}