.user-show-page-container {
    display: flex;
    margin: 45px 23%;
}

.user-show-left-container {
    width: 40%;
    padding: 0 8px;
    min-width: 260px;
}

.user-show-left-box {
    border: 1px solid #DDDDDD;
    border-radius: 12px;
    padding: 24px;
}

.user-show-profile-container {
    display: flex;
    justify-content: center;
}

.user-show-profile {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.user-show-left-upper-bullets {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 600;
}

.user-show-left-upper-bullets i {
    font-size: 20px;
    width: 25px;
    margin-right: 8px;
}

.user-show-left-divider {
    margin: 32px 0;
    border: 1px solid #EBEBEB;
}

.user-show-left-lower-title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 24px;
}

.user-show-left-lower-bullets {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 14px;
}

.user-show-left-lower-bullets i {
    margin-right: 8px;
}

.user-show-left-lower-bullets a {
    text-decoration: none;
    color: #222222;
}

.user-show-left-lower-bullets a:hover {
    font-weight: 600;
}

.user-show-left-lower-message {
    font-size: 12px;
    padding-top: 16px;
}

.user-show-left-lower-message span {
    font-weight: 600;
    text-decoration: underline;
}

.user-show-right-container {
    width: 60%;
    margin: 0 60px;
    min-width: 632px;
}

.user-show-right-title {
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 8px;
}

.user-show-right-date {
    font-size: 13px;
    line-height: 18px;
    color: #717171;
    margin-bottom: 8px;
}

.user-show-right-about {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #DDDDDD;
    margin-top: 48px;
    padding-bottom: 12px;
}

.user-show-right-about h1 {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
}

.user-show-right-description {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 32px;
}

.user-show-right-bullets {
    margin-bottom: 16px
}

.user-show-right-bullets i {
    margin-right: 8px;
}

.user-show-right-projects-container {
    padding: 20px 0;
    /* border-bottom: 1px solid #DDDDDD; */
}

.user-show-right-projects-title {
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 20px;
}

.user-show-right-projects {
    display: flex;
    justify-content: space-between;
}

.user-show-project {
    display: flex;
    flex-direction: column;
    width: 306px;
    color: black;
    text-decoration: none;
}

.user-show-project-image {
    border: 1px solid #DDDDDD;
    border-radius: 12px;
    margin-bottom: 10px;
}

.user-show-project h1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 6px;
}

.user-show-project span {
    line-height: 18px;
    margin-bottom: 6px;
}

