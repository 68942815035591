.listing-show-images-container {
    display: flex;
    min-width: 455px;
}

.listing-show-image-large-container {
    padding-right: 8px;
}

.listing-show-image-large {
    object-fit: cover;
    max-height: 560px;
    width: 50%;
    margin-right: 4px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.listing-show-image-small-container {
    display: grid;
    grid-template-columns: auto auto;
    height: 100%;
    margin-left: 4px;
    gap: 8px;
    width: 50%;
}

.listing-show-image-small {
    object-fit: cover;
    height: 276px;
    max-width: 306px;
    width: 100%
}

#image-2 {
    border-top-right-radius: 12px;
}

#image-4 {
    border-bottom-right-radius: 12px;
}