.reservations-index-container {
    margin: 0 17%;
}

.reservations-index-trips {
    font-size: 32px;
    font-weight: 600;
    margin: 36px 0 24px;
}

.reservation-index-reservations {
    font-size: 22px;
    font-weight: 500;
    margin: 24px 0 12px;
}

.no-reservations-message {
    font-size: 16px;
    margin: 26px 0;
    color: rgb(113, 113, 113);
}

.reservation-container-past-trips {
    display: grid;
    grid-template-columns: auto auto auto;
}


