.listing-show-details-amenities {
    padding: 48px 0;
}

.listing-show-details-amenities h1 {
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    padding: 0 0 24px
}

.listing-show-details-amenities-list {
    display: grid;
    grid-template-columns: auto auto;
}

.listing-show-details-amenities-list-item {
    display: flex;
    align-items: center;
    padding: 0 0 20px;
    font-size: 16px;
}

.listing-show-details-amenities-list-item img {
    height: 26px;
    width: 26px;
    padding-right: 15px;
}