.listing-show-reservations-form {
    position: sticky;
    top: 80px;
    padding-bottom: 48px;
}

.reservation-form-container {
    margin-top: 10%;
    margin-left: 18%;
    border-radius: 12px;
    border: 1px solid #DDDDDD;
    box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
}

.reservation-form-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 8% 5%;
}

.reservation-form-header-price {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
}

.reservation-form-header-price span {
    font-size: 22px;
    font-weight: 600
}

.reservation-form-header-rating span{
    font-size: 14px;
    font-weight: 600;
    padding-left: 4px
}

.reservation-form-header-rating i {
    font-size: 12px;
}

.reservation-form-header-rating a{
    color:rgb(128, 128, 128);
    font-size: 14px;
    font-weight: 600;
    padding-left: 4px
}

.reservation-form {
    margin: 5%;
}

.reservation-form-dates-container {
    display: flex;
    border: 1px solid #DDDDDD;
    border-radius: 8px 8px 0 0;
    height: 56px;
}

.reservation-form-start-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #DDDDDD;
    width: 50%;
}

.reservation-form-end-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.reservation-form-text {
    font-size: 10px;
    line-height: 12px;
    font-weight: 800;
    margin-bottom: 2px;
}

.reservation-form-date-input {
    cursor: pointer;
    border: none;
    outline: none;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-weight: 400px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: hidden;
    width: 70%;
    max-width: 110px;
}

input[type="date"] {
    -webkit-text-fill-color: black;
}

.reservation-form-guests-container {
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-radius: 0 0 8px 8px;
}

.reservation-form-guests {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 56px;
    width: 50%;
}

.reservation-form-guests-input {
    cursor: pointer;
    border: none;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-weight: 400px;
    line-height: 20px;
    width: 18%;
    min-width: 36px;
}

.reservation-form-errors {
    display: flex;
    flex-direction: column;
    color: rgb(193, 53, 21);
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin: 10px 0 10px;
}

.reservation-form-button {
    cursor: pointer;
    background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
    color: rgb(255, 255, 255);
    width: 100%;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    line-height: 20px;
    padding: 14px 24px;
    margin-bottom: 40px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

.reservation-form-button-disabled {
    cursor: pointer;
    background: rgb(128, 128, 128);
    color: rgb(255, 255, 255);
    width: 100%;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    line-height: 20px;
    padding: 14px 24px;
    margin-bottom: 40px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

.reservation-form-rows {
    display: flex;
    justify-content: space-between;
    margin: 5%;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
}

.reservation-form-row-type {
    text-decoration: underline;
}

.reservation-form-divider {
    margin: 7% 5%;
    border: 1px solid #DDDDDD;
}

.reservation-form-row-total {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600
}

.rare-find-container {
    margin-top: 24px;
    margin-left: 18%;
    border-radius: 12px;
    border: 1px solid #DDDDDD;
}

.rare-find {
    display: flex;
    padding: 24px;
}

.rare-find-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    margin-right: 16px
}

.rare-find-text span {
    font-weight: 600;
    margin-right: 8px
}

.rare-find-logo {
    height: 32px;
    width: 32px;
}