.modal-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1
  }
  
.modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
  
/* .modal {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  position: relative;
  background-color:rgb(255, 255, 255);
  width: 100%;
  max-width: 568px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(0 0 0 / 28%) 0px 8px 28px;
  border-radius: 12px;
  animation-duration: 400ms;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-name: keyframe_d37zz3;
  overflow: clip  ;
} */
.modal {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  background: rgb(255, 255, 255);
  position: relative;
  width: 100%;
  max-width: 568px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(0 0 0 / 28%) 0px 8px 28px;
  border-radius: 12px;
  animation-duration: 400ms;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-name: keyframe_d37zz3;
  overflow: scroll
}

.modal::-webkit-scrollbar {
  display: none;
}

.large-modal {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  background: rgb(255, 255, 255);
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(0 0 0 / 28%) 0px 8px 28px;
  border-radius: 12px;
  animation-duration: 400ms;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-name: keyframe_d37zz3;
  overflow: scroll
}

.large-modal::-webkit-scrollbar {
  display: none;
}