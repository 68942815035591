.nav-bar-container {
    height: 80px;
    border-bottom: 1px solid #DDDDDD;
}

.nav-bar-index-container {
    height: 80px;
    border-bottom: 1px solid #DDDDDD;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.nav-bar {
    display: flex;
    justify-content:space-between;
    align-items: center;
    margin: 0 17%;
}

.nav-bar-index {
    display: flex;
    justify-content:space-between;
    align-items: center;
    margin: 0 70px;
}

.left-nav {
    width: 242px;
}

.logo-button {
    color: #ff385c;
    height: 80px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1px;
}

.logo-button img {
    height: 35px;
    width: 35px;
    padding-right: 5px;
}

.logo-button h1 {
    display: flex;
    align-items: center;
}

.search-bar {
    border: 1px solid #DDDDDD;
    border-radius: 40px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 5%);
    min-width: 238px;
    width: 300px;
}

.search-text-container {
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
}

.search-text-box {
    width: 100%;
}

.search-text {
    font-size: 14px;
    font-weight: 500;
    margin: 3px 0;
    height: 40px;
    width: 95%;
    border: none;
    padding-left: 8px;
    outline: 0;

}

.search-button {
    height: 32px;
    width: 35px;
    margin: 8px 7px 8px 0;
    background: #FF385C;
    border: none;
    border-radius: 50%;
    cursor: pointer
}

.search-button i {
    font-size: 12px;
    color: white;
}


.right-nav {
    width: 242px;
}