#star-button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 18px;
  }
  .on {
    color: #000;
  }
  .off {
    color: #ccc;
  }