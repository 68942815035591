.listing-show-page-container {
    margin: 0 17%;
}

.listing-show-header-container {
    height: 90px;
    margin-top: 24px;
}

.listing-show-header-title {
    font-size: 26px;
    line-height: 30px;
    font-weight: 600
}

.listing-show-header-description {
    display:flex;
    align-items: center;
    margin: 8px 0;
    font-weight: 600;
}

.listing-show-header-description i {
    font-size: 12px;
    margin-right: 3px;
}

.listing-show-rating {
    margin-right: 3px;
}

.listing-show-reviews {
    margin-right: 5px;
    color: black;
    text-decoration: underline;
}

.listing-show-location {
    text-decoration: underline;
    color: black;
    margin-left: 5px;
}

.listing-show-container {
    display: flex;
    border-bottom: 1px solid #DDDDDD;
}

.listing-show-details {
    width: 100%;
}

.listing-show-details-title-container {
    display: flex;
    justify-content: space-between;
    padding: 48px 0 28px; 
    border-bottom: 1px solid #DDDDDD;
}

.listing-show-details-title h1 {
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
}

.listing-show-details-title h2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 8px;
}

.listing-show-details-title-profile {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    margin-right: 5px;
}

.listing-show-aircover, .listing-show-details-description {
    padding: 32px 0 48px;
    line-height: 24px;
    font-size: 16px;
    border-bottom: 1px solid #DDDDDD;
}

.listing-show-aircover img {
    height: 30px;
    width: 130px;
    margin-bottom: 16px;
}

.listing-show-reservations-container {
    width: 70%;
}

.listing-show-map-container {
    padding: 48px 0;
    border-bottom: 1px solid #DDDDDD;
}

.listing-show-map-container h1 {
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    padding: 0 0 24px
}

.listing-show-map-container h2 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    margin-top: 24px;
}

.listing-show-reviews-container {
    padding: 48px 0;
    border-bottom: 1px solid #DDDDDD;
}

.listing-show-host-container {
    padding: 48px 0;
}

.listing-show-host-details-left {
    width: 50%;
    margin-right: 100px; 
}

.listing-show-host-title-container {
    display: flex;
    margin-bottom: 24px;
}


.listing-show-host-details-container {
    display: flex;
}

.listing-show-host-profile {
    height: 64px;
    width: 64px;
    border-radius: 50%;
    margin-right: 16px;
}

.listing-show-host-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.listing-show-host-name h1 {
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
}

.listing-show-host-name span {
    color: #717171;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-top: 8px;
}

.listing-show-host-attributes {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
}

.listing-show-host-attribute {
    display: flex;
    align-items: center;
}

.listing-show-host-attribute i {
    font-size: 20px;
    margin-right: 4px;
}

.listing-show-host-attribute h1 {
    font-size: 16px;
    margin-left: 8px;
}

.listing-show-host-description {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
}

.listing-show-host-superhost {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 8px;
}

.listing-show-host-superhost-description {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
}

.listing-show-host-details-right {
    width: 50%;
}

.listing-show-host-right-attribute {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
}

.listing-show-host-contact-button {
    cursor: pointer;
    background: #FFFFFF;
    color: black;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #222222;
    line-height: 20px;
    padding: 12px 23px;
    margin-top: 32px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

