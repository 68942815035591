.filter-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 78px;
    margin: 0 90px;
}

.filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 12px 0 0;
    color: #717171;
    cursor: pointer;
    text-decoration: none;
}

.filter-container:hover{
    color: black;
}

.filter-icon {
    height: 24px;
    width: 24px;
}

.filter-name {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-top: 8px;
}