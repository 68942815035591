.reviews-container h1 {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    padding: 0 0 24px
}

.reviews-container i {
    font-size: 14px;
    padding-right: 8px
}

.reviews-grid-ratings {
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 24px
}

.reviews-grid-ratings-content{
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
}

.reviews-grid-value {
    display: flex;
    margin-right: 21%;
    width: 140px;
}

.reviews-grid-type {
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.reviews-grid-rating-bar {
    background: rgb(221, 221, 221);
    border-radius: 2px;
    height: 4px;
    width: 100%;
    margin-right: 4px;
    margin-top: 6px;
}

.reviews-grid-rating-bar span {
    display: flex;
    align-items: center;
    background: #222222;
    border-radius: 2px;
    font-size: 2px;
}

.reviews-grid-ratings-average {
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-left: 6px;
}

.reviews-grid-comments {
    display: grid;
    grid-template-columns: auto auto;
}

.review-content {
    margin-bottom: 40px;
}

.review-author {
    display: flex;
    margin-bottom: 16px;
}

.review-author img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 12px;
}

.review-author-info {
    display: flex;
    flex-direction: column;
}

.review-author-info span {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.review-author-info h2 {
    color: #717171;
    font-size: 14px;
    font-weight: 400
}

.review-comment {
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}