.edit-reservation-modal {
    display: flex;
    height: 100%
}

.edit-reservation-form-container {
    align-self: center;
    width: 55%;
    margin: 7%;
}

.edit-reservation-form-header {
    font-size: 22px;
    font-weight: 800;
    margin: 8px 0;
}

.edit-reservation-form {
    padding-top: 8px;
}

.edit-reservation-form-dates-container {
    display: flex;
    border: 1px solid #DDDDDD;
    border-radius: 8px 8px 0 0;
    height: 56px;
}

.edit-reservation-form-start-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #DDDDDD;
    width: 50%;
}

.edit-reservation-form-end-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.edit-reservation-form-text {
    font-size: 10px;
    line-height: 12px;
    font-weight: 800;
    margin-bottom: 2px;
}

.edit-reservation-form-date-input {
    cursor: pointer;
    border: none;
    outline: none;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-weight: 400px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: hidden;
    width: 70%;
}

input[type="date"] {
    -webkit-text-fill-color: black;
}

.edit-reservation-form-guests-container {
    border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-radius: 0 0 8px 8px;
}

.edit-reservation-form-guests {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 56px;
    width: 50%;
}

.edit-reservation-form-guests-input {
    cursor: pointer;
    border: none;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-weight: 400px;
    line-height: 20px;
    width: 18%;
    min-width: 36px;
}

.edit-reservation-form-errors {
    display: flex;
    flex-direction: column;
    color: rgb(193, 53, 21);
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin: 10px 0 10px;
}

.edit-reservation-form-button {
    cursor: pointer;
    background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
    color: rgb(255, 255, 255);
    width: 100%;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    line-height: 20px;
    padding: 14px 24px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

.edit-reservation-form-rows {
    display: flex;
    justify-content: space-between;
    margin: 5%;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
}

.edit-reservation-form-divider {
    margin: 7% 5%;
    border: 1px solid #DDDDDD;
}

.edit-reservation-form-row-total {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600
}

.edit-reservation-delete-button {
    cursor: pointer;
    background: linear-gradient(to right, rgb(230, 30, 77) 0%, rgb(227, 28, 95) 50%, rgb(215, 4, 102) 100%);
    color: rgb(255, 255, 255);
    width: 100%;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    line-height: 20px;
    padding: 14px 24px;
    margin-top: 30px;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}